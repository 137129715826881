import MainContent from '@/components/MainContent';
import MainFooter from '@/components/MainFooter';
import MainHeader from '@/components/MainHeader';
import Navbar from '@/components/Navbar';
import StickyBars from '@/components/StickyBars';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'DefaultLayout',
  setup() {
    useHead({
      htmlAttrs: {
        'data-layout': 'default',
      },
    });
  },
  render(): VNode {
    return (
      <>
        <MainHeader>
          <Navbar />
        </MainHeader>
        <StickyBars />
        <MainContent class="max-w-screen-2xl">
          {this.$slots.default?.()}
        </MainContent>
        <MainFooter />
      </>
    );
  },
});
